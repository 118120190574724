@font-face {
    font-family: "PTRootUIWebBold";
    src: url("../font/PT/PT-Root-UI_Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebMedium";
    src: url("../font/PT/PT-Root-UI_Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

.entries-title p {
    color: grey;
    font-size: 0.875rem;
    line-height: 1.2rem;
    margin-top: 20px;
}

.LandingContent {
    display: block;
    float: center;
    margin: 60px auto 60px auto;
}

.login-prompt {
    width: 66.7%;
    margin-left: auto;
    margin-right: auto;
    background: transparent;
    display: flex;
}

.login-prompt-left {
    margin-top: -60px;
}

.login-prompt p {
    color: #370075;
    font-size: 0.875rem;
    line-height: 1.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.login-prompt-right {
    margin-left: 40px;
    margin-top: 20px;
}

.cite-container {
    width: 100%;
    background-color: #620097;
}

.cite-content {
    width: 66.7%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cite-content h3 {
    padding-top: 40px;
    color: white;
}

.SupportLogos {
    height: 65px;
    text-align: left;
}

.MuiTypography-displayBlock-268 {
    color: #370075;
}

.landing-login-model {
    background: transparent;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.tipars-entry-content{
    width:66.7%;
    margin:auto
 }
 .LandingContent img{
    width: 66.7%
 }
.homeGraphicMobile{
    display: none;
}
@media screen and (max-width: 959px) {
    .featureTitleText {
        width: 50%;
    }
    .featurePage {
        height: auto;
        background-size: 160%;
        padding: 0;
    }
    .featureText {
        width: 100%;
        position: relative;
        margin-top: 40px;
    }
    .feature1 img {
        width: 100%;
        float: none;
        margin: auto;
        top: 1%;
        position: relative;
    }
    .feature1 {
        height: 500px;
        margin-bottom: 15%;
    }
    .feature2 {
        height: 500px;
        margin-top: 0;
        margin-bottom: 15%;
    }
    .feature2 img {
        width: 100%;
        float: none;
        margin: auto;
        top: 1%;
        position: relative;
    }
}

@media screen and (max-width: 599px) {
    .featureTitleText {
        width: 90%;
    }
    .featurePage {
        width: 90%;
        margin: auto;
        background-size: 200%;
    }
    .feature1 {
        margin-bottom: 0;
    }
    .feature2 {
        margin-bottom: 5%;
    }
    .entries-container {
        width: 90%;
        margin-top: 20px;
    }
    .login-prompt {
        width: 90%;
        padding-top: 20px;
    }
    .cite-content{
        width: 90%;
    }
    .tipars-entry-content{
       width:90%;
       margin:auto
    }
    .LandingContent img{
        width: 40%;
     }
     .SupportLogos{
        height: auto;
     }
     .LandingContent{
        margin-top: 20px;
        margin-bottom: 0
     }
     .homeGraphic{
        display: none;
    }
    .homeGraphicMobile{
        display: block;
        margin: auto;
    }
    
}