@font-face {
    font-family: "PTRootUIWebBold";
    src: url("./font/PT/PT-Root-UI_Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebLight";
    src: url("./font/PT/PT-Root-UI_Light.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebMedium";
    src: url("./font/PT/PT-Root-UI_Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebRegular";
    src: url("./font/PT/PT-Root-UI_Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}


/* ::-webkit-scrollbar {
    width: 0;
    background: transparent;
} */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
/* ol, */
ul,
/* li, */
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    background-color: white;
}

/* ol,
ul {
    list-style: none;
} */

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

:root {
    font-family: "PTRootUIWebRegular", sans-serif;
    text-decoration: none;
    font-display: auto;
}

.App {
    text-align: center;
    background-color: white;
    min-height: 100vh;
}

h1 {
    font-family: "PTRootUIWebBold", sans-serif;
    letter-spacing: 3px;
    font-size: 4rem;
    color: white;
}

h2 {
    font-family: "PTRootUIWebRegular", sans-serif;
    font-size: 24px;
    color: white;
}

h3 {
    font-family: "PTRootUIWebBold", sans-serif;
    font-size: 1.5rem;
    color: #370075;
    text-align: left;
}

p {
    color: #293241;
    font-size: 1rem;
    font-family: "PTRootUIWebRegular", sans-serif;
    /* line-height: 1.3rem; */
    margin-block-start: 0.3rem;
    margin-block-end: 0.3rem;
    text-align: left;
}

a {
    text-decoration: none;
    font-family: "PTRootUIWebBold", sans-serif;
    color: #370075;
}

.td {
    font-size: 0.875rem;
}

.th {
    font-family: "PTRootUIWebBold", sans-serif;
    color: #370075;
    white-space: nowrap;
    overflow: hidden;
}

.special-color {
    color: #f87700;
}

button {
    font-family: "PTRootUIWebBold", sans-serif;
    border: none;
    background: none;
}

.primary-button {
    min-width: 150px;
    height: 38px;
    border-radius: 200px;
    background: #370075;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    margin-top: 15px;
    font-family: "PTRootUIWebBold", sans-serif;
}

.primary-button:hover {
    cursor: pointer;
    background: #620097;
    transition: all 0.3s ease-out;
}

.secondary-button {
    min-width: 150px;
    height: 38px;
    border-radius: 200px;
    outline: none;
    border: 1px solid #370075;
    color: #370075;
    font-size: 1.2rem;
    margin-top: 15px;
    font-family: "PTRootUIWebBold", sans-serif;
}

.secondary-button:hover {
    cursor: pointer;
    color: #620097;
    border: 1px solid #620097;
    transition: all 0.3s ease-out;
}

.dangerous-button {
    min-width: 150px;
    height: 38px;
    border-radius: 200px;
    outline: none;
    border: 1px solid #f87700;
    color: #f87700;
    font-size: 1.2rem;
    margin-top: 15px;
    font-family: "PTRootUIWebBold", sans-serif;
}

.dangerous-button:hover {
    cursor: pointer;
    color: #f4c294;
    border: 1px solid #f4c294;
    transition: all 0.3s ease-out;
}

.disabled-button {
    min-width: 150px;
    height: 38px;
    border-radius: 200px;
    background: #e5dcee;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    margin-top: 15px;
}

.homepage-button {
    width: 180px;
    background-color: #370075;
    border: 2px solid #370075;
    font-size: 24px;
    color: #fdba31;
    padding: 8px 0;
    margin: auto;
    border-radius: 200px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    margin: 20px 0 40px 0;
}

.homepage-button:hover {
    background: transparent;
    color: #370075;
    box-shadow: none;
    transition: all 0.3s ease-out;
}

input,
textarea {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    outline: none;
}

input:focus,
textarea:focus {
    box-shadow: inset 0 0 0 1.5px #620097;
}

.form-location input:focus {
    box-shadow: none;
}

.profile-dropdown input:focus {
    box-shadow: none;
}

#redI {
    color: #f87700;
}

#login {
    color: white;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.form-input-btn {
    min-width: 150px;
    height: 42px;
    margin-top: 30px;
    margin-bottom: 18px;
    border-radius: 200px;
    background: #370075;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
}

.form-input-btn:hover {
    cursor: pointer;
    background: #620097;
    transition: all 0.4s ease-out;
}

.form-disabled-btn {
    min-width: 180px;
    height: 42px;
    margin-top: 30px;
    margin-bottom: 18px;
    border-radius: 200px;
    background: #e5dcee;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    font-family: "PTRootUIBold", sans-serif;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.SignUpButton {
    width: 180px;
    color: white;
    font-size: 1.2rem;
    margin: auto;
    font-family: "PTRootUIWebBold", sans-serif;
    padding: 8px 0;
}

#country-select-demo {
    color: black;
}

.social-media-container img {
    height: 100%;
    width: auto;
    margin: 0 16px 0 0;
}

.footer p {
    font-size: 0.95rem;
    color: white;
    line-height: 1.4rem;
    text-align: left;
    margin: 0;
}

.footer h2 {
    font-size: 1rem;
    font-family: "PTRootUIWebBold", sans-serif;
    text-align: left;
}

.footer-navigater-container {
    display: flex;
    justify-content: space-between;
}

.footer-navigater {
    margin: 66px 0 66px 66px;
}

.social-media-container {
    height: 18px;
    width: 100%;
    /* display: flex; */
    text-align: left;
    margin-top: 40px;
}

.external-pages-container {
    min-height: 100vh;
}

.external-pages {
    text-align: left;
    margin-top: 48px;
}

.external-pages h1 {
    color: #370075;
    font-size: 2.5rem;
    font-family: "PTRootUIWebBold", sans-serif;
    letter-spacing: 0;
    margin-bottom: 36px;
}

.external-pages h2 {
    color: #fdba31;
    font-size: 1.25rem;
    font-family: "PTRootUIWebMedium", sans-serif;
}

.external-pages h3 {
    color: #370075;
    font-size: 1.25rem;
    font-family: "PTRootUIWebBold", sans-serif;
}

.external-pages h4 {
    color: grey;
    font-size: 0.95rem;
    font-family: "PTRootUIWebMedium", sans-serif;
}

.external-pages h5 {
    color: #370075;
    font-size: 1.75rem;
    font-family: "PTRootUIWebBold", sans-serif;
    line-height: 2.3rem;
}

.external-pages p {
    color: gray;
    font-size: 0.95rem;
    line-height: 1.4rem;
}
.external-pages a {
    font-size: 0.95rem;
}

.text-button-container {
    display: flex;
    justify-content: space-between;
}

.text-container-l {
    width: 60%;
}

.text-container-m {
    width: 88%;
}

.tutorial-content-left {
    margin-top: 60px;
}

.tutorial-content-container {
    display: flex;
    justify-content: space-between;
}

.tutorial-content-right {
    margin-top: 10%;
    width: 45%;
}

.ru-content-container {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-top: 20px;
    padding: 25px 40px;
    display: flex;
    justify-content: space-between;
}

.ru-content p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-height: 1.35rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ru-content-container-wrapper :hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.ru-content-container-wrapper :hover p {
    display: block;
    overflow: visible;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.ru-content-container-wrapper :hover h5 {
    display: none;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.ru-content-container :hover {
    box-shadow: none;
}

.arrow-container {
    float: right;
    text-align: right;
}

.vertical-divider {
    width: 20px;
    height: 15px;
    border-right: lightgrey 1px solid;
    margin-right: 20px;
    margin-top: 10px;
}

.icon-feature-container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 88%;
}

.icon-feature-container p {
    font-size: 0.8rem;
}

.icon-feature {
    width: 30%;
}

.about-box {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-top: 30px;
    padding: 25px 40px;
    width: 85%;
    float: right;
}

.contact-form-container {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 25px min(50px, 6%);
    width: 85%;
    float: right;
}

.form-group {
    margin-top: 10px;
}

.external-page-divider {
    margin-top: 36px;
    width: 88%;
    border-bottom: 1px lightgrey solid;
}

.profile-content {
    text-align: left;
    margin-top: 4%;
    width: 100%;
    margin-left: auto;
}

.our-team-content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-left: -2.7%;
    margin-right: -2.7%;
}

.member-card {
    display: block;
    margin: 36px 2.66%;
    width: min(280px, 28%);
    height: auto;
    /* box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1); */
    border-bottom: lightgrey solid 1px;
}

.member-img-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    margin: auto;
}

.member-img-container img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 2px;
}

.member-text-container {
    width: 100%;
    margin: 20px auto 0 auto;
    padding-bottom: 5%;
}

.member-text-container p {
    /* display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden; */
    min-height: 60px;
}

.member-contact-container {
    width: 100%;
    margin: 20px auto;
    display: flex;
}

.member-contact-container a img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.member-contact-container a:hover {
    transform: translateY(-3px);
}

.fgpw-form-container {
    text-align: left;
    margin-top: 10%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.fading-image {
    height: 60%;
    width: 60%;
    position: absolute;
    left: 18%;
    top: 20%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.fading-image.bottom {
    z-index: 0;
    opacity: 0;
}

.fading-image.top {
    z-index: 1;
    opacity: 1;
}

.form-group span {
    font-size: 0.75rem;
}

.file-form {
    text-align: left;
}

@media screen and (max-width: 959px) {
    .features {
        display: block;
    }
    .BoxText {
        text-align: Left;
        display: block;
    }
    .BoxImg {
        text-align: Left;
        margin-top: 20px;
        width: 100%;
    }
    .BoxImg img {
        width: 100%;
        max-height: 100%;
        display: block;
    }
    .logo {
        display: none;
    }
    .footer-navigater-container {
        margin-left: 25%;
        margin-right: -25%;
    }
    .footer-navigater {
        float: left;
        margin: 0 0 40px 0;
    }
    .social-media-container {
        margin: 40px 0 40px 0;
    }
    .text-button-container {
        display: block;
    }
    .text-container-l {
        width: 100%;
    }
    .text-container-m {
        width: 100%;
    }
    .icon-feature-container {
        width: 100%;
    }
    .contact-form-container {
        width: 100%;
    }
    .tutorial-content-container {
        display: block;
    }
    .tutorial-content-container div {
        width: 100%;
    }
    .ru-content-container {
        padding: 25px 20px;
    }
    .about-box {
        width: 100%;
    }
    .icon-feature-container {
        margin-top: 40px;
    }
    .external-page-divider {
        width: 100%;
    }
    .fgpw-form-container {
        width: 80%;
    }
    .member-card {
        width: 240px;
        margin: 32px 32px;
    }
    .member-card {
        width: 45%;
        margin: 36px 0;
    }
    .our-team-content-container {
        margin: auto;
        justify-content: space-between;
    }
}

@media screen and (max-width: 599px) {
    .FeatureBox {
        width: 90%;
        margin: 40px auto;
    }
    .footer-container {
        width: 90%;
        margin: auto;
    }
    .footer-navigater-container {
        margin-left: 0;
        margin-right: 0;
    }
    .external-pages {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .member-card {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 36px 0;
    }
    .our-team-content-container {
        margin: auto;
        justify-content: center;
    }
    .member-info-container {
        width: 60%;
    }
    .member-img-container {
        width: 32%;
        padding-top: 32%;
        position: relative;
        margin-left: 0;
        margin-top: 0.4rem;
    }
    .member-text-container {
        margin-top: 0;
    }
    .primary-button {
        min-width: 100px;
        font-size: 1rem;
    }
    .secondary-button {
        min-width: 100px;
        font-size: 1rem;
    }
    .dangerous-button {
        min-width: 100px;
        font-size: 1rem;
    }
    .disabled-button {
        min-width: 100px;
        font-size: 1rem;
    }
    p{
        font-size: 0.875rem;
    }
    .App{
        min-width: none;
      }
}