path.rp-branch {
    transition: all 0.2s;
    fill: none;
    shape-rendering: crispEdges;
}

text.rp-label {
    font-family: "PTRootUIWebRegular", sans-serif;
    font-size: 12px;
    transition: all 0.2s;
    color:aqua
}

text {
    font-family: "PTRootUIWebRegular", sans-serif;
    color: aqua;
}

.rp-branch-tracer {
    stroke: #bbb;
    stroke-dasharray: 3, 4;
    stroke-width: 1px;
}

path.rp-branch:hover {
    stroke-width: 10px;
}

.btn-primary {
    background-color: #370075;
}

line {
    color: #bbb;
}

path.domain {
    color: #bbb;
}

.tick text {
    color: #bbb;
}