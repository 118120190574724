@font-face {
    font-family: "PTRootUIWebBold";
    src: url("../font/PT/PT-Root-UI_Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebMedium";
    src: url("../font/PT/PT-Root-UI_Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

.tipars-container {
    width: 66.6%;
    margin-left: 2%;
    margin-top: 30px;
}

.advanced-options-container {
    text-align: left;
    padding: 10px 0 20px 0;
}

.advanced-options {
    display: flex;
    /* justify-content: space-between; */
    width: 94%;
    min-height: 80px;
}

.advanced-options button {
    color: #370075;
    font-size: 1.2rem;
    text-decoration: underline;
    padding: 0;
}


/* span {
    color: #f87700;
} */

button {
    cursor: pointer;
}

label {
    font-size: 1.2rem;
    color: #370075;
    font-family: "PTRootUIWebBold", sans-serif;
}

.upload-container {
    padding: 0px 0 10px 0;
    border-radius: 2px;
    text-align: left;
    min-height: 140px;
}

.upload-content {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.submit-button-container {
    text-align: right;
    padding: 20px 30px;
}

.upload-container button p {
    color: #370075;
    font-family: PTRootUIWebBold;
    text-decoration: underline;
    line-height: 2rem;
    font-size: 1rem;
    max-width: none;
    margin: 0;
}

#uploadIcon {
    margin-top: 6px;
}

.upload-container p {
    text-align: left;
    padding-left: 10px;
    color: black;
    font-size: 1rem;
    margin: 0;
    line-height: 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 94%;
    border: 1px solid lightgrey;
}

.upload-container span {
    color: #370075;
}

.prediction-left {
    width: 45%;
}

.prediction-sequence {
    padding: 20px 30px 30px 30px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-top: 20px;
}

.sequence-input {
    font-family: "PTRootUIWebRegular", sans-serif;
}

.prediction-sequence textarea {
    border: 1px solid lightgrey;
    border-radius: 2px;
    margin-top: 20px;
}

.command-area {
    background-color: #e5dcee;
    padding: 2px 6px;
    margin-top: 6px;
}

.MuiMobileStepper-dot {
    width: 4px;
    height: 4px;
}

.MuiPaper-root {
    padding: 0;
}

.MuiPaper-root p {
    color: #293241;
}

.MuiPaper-root h2 {
    color: #370075;
}

.MuiDialogContent-root {
    border-bottom: none;
}

.task-card {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    text-align: left;
    padding: 20px;
    margin-top: 30px;
}

.time-container {
    display: flex;
    justify-content: space-between;
}

.model-status-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.result-button-container {
    text-align: right;
    margin-top: 20px;
    display: flex;
}

.resultButton {
    min-width: 150px;
    height: 42px;
    border-radius: 2px;
    background: #370075;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
}

.model-status-container h3 {
    color: #370075;
    font-size: 2rem;
}

.model-status-container p {
    color: #370075;
    font-family: "PTRootUIWebBold", sans-serif;
}

.time-container p {
    color: grey;
    font-size: 0.875rem;
}

.id-file-coantiner p {
    color: grey;
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 0.875rem;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
}

.id-file-coantiner {
    margin-top: 15px;
    width: 100%;
}

.id-file-coantiner span {
    color: grey;
    font-family: "PTRootUIWebBold", sans-serif;
}

.id-file-coantiner a {
    color: #e5dcee;
    font-family: "PTRootUIWebBold", sans-serif;
    text-decoration: underline;
}

.time-container span {
    font-family: "PTRootUIWebBold", sans-serif;
    color: grey;
}

.status-container {
    display: flex;
    margin-top: 10px;
}

.status-container img {
    width: 21px;
    height: 21px;
    margin-right: 10px;
    margin-top: 2px;
}

.task-information-container {
    text-align: left;
    padding: 30px 0;
    margin-bottom: 30px;
}

.id-file-result-container {
    display: flex;
    justify-content: space-between;
}

.preview-input {
    width: 100%;
    height: 28px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 0px 1px lightgrey;
    border: none;
    padding-left: 6px;
    margin-top: 6px;
}

.preview-cell {
    max-width: 100%;
    min-height: 90px;
}

.preview-cell p {
    font-family: "PTRootUIWebRegular", sans-serif;
    color: #293241;
    font-size: 0.875rem;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.preview-buttons {
    float: right;
    display: flex;
}

.preview-input::placeholder {
    color: lightgrey;
}

.task-content {
    text-align: left;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid lightgrey;
}

.task-bottom-noti {
    text-align: left;
    color: grey;
    margin-top: 30px;
    font-size: 0.875rem;
}

.task-bottom-noti span {
    font-family: "PTRootUIWebBold", sans-serif;
}

.result-container {
    width: 100%;
}

.result-logo-container {
    margin-right: 40px;
}

.task-center {
    margin-bottom: 36px;
}

.pie-chart-container {
    display: flex;
    border-left: lightgrey solid 1px;
    border-bottom: lightgrey solid 1px;
    margin-bottom: 30px;
}

.pie-chart-cell-container {
    width: 50%;
    border-right: lightgrey solid 1px;
}

.pie-chart-cell {
    width: 100%;
    /* padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%; */
    padding: 2% 0;
}

.pie-chart {
    display: flex;
    justify-content: space-between;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
}


/* .arg-legend {
    margin-left: 30px;
} */

.prediction-legend {
    margin-left: 10px;
}

.prediction-legend g {
    max-width: 20px;
}

.prediction-legend-container {
    max-height: 160px;
    overflow-y: auto;
    overflow-x: auto;
}

.pie-chart-header {
    background-color: #f5f5f5;
    width: 100%;
}

.pie-chart-header p {
    width: 100%;
    text-align: center;
    font-family: "PTRootUIWebBold", sans-serif;
    padding: 6px 0;
    border-right: lightgrey solid 1px;
    border-bottom: lightgrey solid 1px;
    border-top: 1px solid lightgrey;
    margin: 0;
    color: #370075;
}

.table-container {
    border-top: lightgrey 1px solid;
    border-left: lightgrey 1px solid;
    border-right: lightgrey 1px solid;
    margin-bottom: 40px;
}

.table-header {
    display: flex;
    width: 100%;
}

.list-item {
    display: flex;
    padding: 6px 10px;
    border-bottom: lightgrey 1px solid;
}

#filter-list-item {
    border: 0;
}

.list-item button {
    padding: 0;
}

.text-button {
    font-size: 0.875rem;
    font-family: "PTRootUIWebMedium", sans-serif;
    margin-left: 10px;
    padding: 0;
    width: 80%;
    text-align: left;
}

.header-menu-container {
    display: flex;
    width: 25%;
    justify-content: center;
    border-right: lightgrey 1px solid;
    border-bottom: lightgrey 1px solid;
    background-color: #f5f5f5;
}

.header-menu-container p {
    text-align: center;
    font-family: "PTRootUIWebBold", sans-serif;
    padding: 6px 0;
    margin: 0;
    color: #370075;
    width: 90%;
}

.header-menu-list-container {
    border-radius: 2px;
    margin-bottom: 2px;
    z-index: 10000;
    background-color: white;
}

.filter-container {
    background-color: white;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-bottom: 4px;
    transition: all 0.3s ease-out;
}

.header-menu-list {
    padding: 0 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    margin-bottom: 2px;
    transition: all 0.3s ease-out;
}

.filter-button {
    width: 48px;
    height: 22px;
    border-radius: 2px;
    background-color: #370075;
    color: white;
    margin: 10px 0;
}

.filter-input {
    border-radius: 2px;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgrey;
    width: 100%;
    margin-top: 10px;
}

::placeholder {
    color: lightgrey;
}

.filter-form {
    padding: 0 8px;
    text-align: right;
}

.task-center-container {
    margin-bottom: 40px;
}

.task-center-container h1 {
    color: #370075;
    text-align: left;
    font-size: 2.5rem;
    font-family: "PTRootUIWebBold", sans-serif;
    letter-spacing: 0;
    margin-top: 20px;
}

#task-card-interrupt {
    margin-right: 20px;
}

.MuiOutlinedInput-root {
    width: 100%;
    border-radius: 2px;
    height: 38px;
}

.mobile-tree-container {
    display: none;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    padding: 10px 10px;
    border-radius: 2px;
    font-family: "PTRootUIWebRegular";
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    width: 18%;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    padding: 0;
}

.option-dropdown-cell {
    width: 30%;
    margin: 8px 0;
}

.tipars-title-container h1 {
    color: #370075;
    text-align: left;
    font-size: 2.5rem;
    font-family: "PTRootUIWebBold", sans-serif;
    letter-spacing: 0;
    margin-top: 20px;
}

#tipars-drawer {
    width: 15.7%;
    height: 100vh
}
.tipars-content-container {
    display: flex;
}

.scroll-tip{
    display: none;
}

#drawer-tipars-cell{
    margin-top: 50px;
}


@media screen and (max-width: 959px) {
    .prediction-content {
        display: block;
    }
    .prediction-left {
        width: 100%;
    }
    .prediction-right {
        width: 100%;
    }
    .prediction-right-content {
        margin: auto;
        float: none;
    }
    .prediction-right {
        margin-top: 20px;
    }
    .prediction-file {
        margin-top: 20px;
    }
    .result-button-container {
        margin-top: 30px;
    }
    .id-file-coantiner p {
        max-width: 200px;
    }
    .pie-chart-container {
        display: block;
    }
    .pie-chart-cell-container {
        width: 100%;
    }
    .pie-chart-header {
        display: block;
    }
    .pie-chart-cell-container {
        display: block;
    }
    .pie-chart-cell {
        width: 100%;
    }
    .tipars-container {
        width: 90%;
    }
    .option-dropdown-cell {
        width: 100%;
        margin: 8px 0;
    }
}

@media screen and (max-width: 599px) {
    .result-container {
        width: 90%;
        margin: auto;
    }
    .result-logo-container {
        margin-right: 0;
    }
    .prediction-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .prediction-container h1 {
        font-size: 1.8rem;
        margin-top: 10px;
    }
    .process-task-content {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .model-status-container h3 {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .task-center {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .id-file-coantiner p {
        max-width: 150px;
    }
    .task-center-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .result-button-container {
        justify-content: space-between;
    }
    .upload-container {
        padding: 0;
        min-height: 145px;
    }
    .advanced-options-container {
        padding-top: 10px;
    }
    .option-dropdown-cell {
        min-height: 90px;
    }
    .tree-container {
        display: none;
    }
    .mobile-tree-container {
        width: 90%;
        line-height: 1.2rem;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    #tipars-drawer {
        width: 100%;   
        height: 100px;
   }
   #tipars-drawer img{
    display: none;
   }

   .tipars-container {
    margin: auto;
}

   .tipars-content-container {
    display: block;
  }

  .scroll-tip{
    display: block;
  }

  #drawer-tipars-cell{
    margin-top: 0;
  }

  .task-content{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .task-bottom-noti{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

}